import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container wrapper"},[_c('h1',{staticClass:"pageHeader my-12"},[_vm._v("Agency Connections")]),_c('div',{staticClass:"d-flex mb-12"},[_c(VBtn,{staticClass:"tabBtn me-5",class:_vm.currentTab == 'linkage' ? 'active' : '',on:{"click":function($event){_vm.currentTab = 'linkage'}}},[_vm._v(" Agency ("+_vm._s(_vm.linkage.length)+") ")]),_c(VBtn,{staticClass:"tabBtn me-5",class:_vm.currentTab == 'linkage' ? '' : 'active',on:{"click":function($event){_vm.currentTab = 'pending'}}},[_vm._v(" Pending ("+_vm._s(_vm.pending.length)+") ")]),_c('div',{staticClass:"ml-auto align-self-center"},[_c(VMenu,{attrs:{"dark":"","left":"","z-index":"0","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({staticClass:"icon",attrs:{"color":"white"}},on),[_vm._v(" mdi-dots-vertical ")])]}}])},[_c(VList,[(_vm.currentTab != 'pending')?_c(VListItem,{on:{"click":function($event){return _vm.$openModal('linkAgencyModal')}}},[_c(VListItemTitle,{staticClass:"px-8"},[_vm._v("Add Agency")])],1):_c(VListItem,{on:{"click":function($event){_vm.isSelect = true}}},[_c(VListItemTitle,{staticClass:"px-8"},[_vm._v("Select")])],1)],1)],1)],1)],1),_c('div',{staticClass:"mb-2 d-flex"},[_c('div',{staticClass:"align-self-center"},[_vm._v(" ("+_vm._s(_vm.currentTab == 'linkage' ? _vm.linkage.length : _vm.pending.length)+") ")]),(_vm.isSelect && _vm.currentTab != 'linkage')?_c('div',{staticClass:"ml-auto text-uppercase font-weight-bold lg-font"},[_c('span',{staticClass:"cursor-pointer",on:{"click":_vm.approveAgencies}},[_vm._v("Approve")]),_c('span',{staticClass:"mx-4 font-weight-light"},[_vm._v("|")]),_c('span',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.approveAgencies(false)}}},[_vm._v("Reject")])]):_vm._e()]),(_vm.currentTab == 'linkage' ? _vm.linkage.length : _vm.pending.length)?_c(VRow,{staticClass:"mb-12 pb-12",attrs:{"no-gutters":""}},_vm._l((_vm.currentTab == 'linkage' ? _vm.linkage : _vm.pending),function(item,index){return _c(VCol,{key:index,staticClass:"userContainer d-flex",class:index != 0 ? 'mt-5' : '',attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"d-flex w-100"},[(_vm.isSelect && _vm.currentTab != 'linkage')?_c(VCheckbox,{staticClass:"pl-5 align-self-center",attrs:{"on-icon":"mdi-check-circle-outline","off-icon":"mdi-checkbox-blank-circle-outline","color":"primary","value":item.request_id,"number":"","dark":""},model:{value:(_vm.requestId),callback:function ($$v) {_vm.requestId=$$v},expression:"requestId"}}):_vm._e(),_c('router-link',{staticClass:"text-decoration-none d-flex white--text w-100",attrs:{"to":{
            name: _vm.routeName.PROFILE,
            query: {
              id: item.agency_id
            }
          }}},[_c(VRow,{staticClass:"my-5 ps-8",attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"py-2 align-self-center d-flex",attrs:{"cols":"12","md":"12"}},[_c('div',[_c('div',{staticClass:"titleFont grey--text"},[_vm._v(" "+_vm._s(_vm.getAgencyCategoryDesc(item.agency_category))+" ")]),_c('div',{staticClass:"nameFont"},[_vm._v(" "+_vm._s(item.agency)+" ")])]),_c('div',{staticClass:"ml-auto me-5 align-self-center"},[_c(VIcon,{staticClass:"icon",attrs:{"color":"white"}},[_vm._v(" mdi-chevron-right ")])],1)])],1)],1)],1)])}),1):_c(VRow,{staticClass:"mb-12 pb-12",attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"userContainer d-flex px-5 py-8",attrs:{"cols":"12","md":"12"}},[_c('div',[_vm._v(" "+_vm._s(_vm.currentTab == 'linkage' ? 'No linked agency' : 'No pending agency')+" ")])])],1),_c('LinkAgencyModal')],1)}
var staticRenderFns = []

export { render, staticRenderFns }