<template>
  <div class="container wrapper">
    <h1 class="pageHeader my-12">Agency Connections</h1>
    <div class="d-flex mb-12">
      <v-btn
        class="tabBtn me-5"
        :class="currentTab == 'linkage' ? 'active' : ''"
        @click="currentTab = 'linkage'"
      >
        Agency ({{ linkage.length }})
      </v-btn>
      <v-btn
        class="tabBtn me-5"
        :class="currentTab == 'linkage' ? '' : 'active'"
        @click="currentTab = 'pending'"
      >
        Pending ({{ pending.length }})
      </v-btn>
      <div class="ml-auto align-self-center">
        <v-menu dark left z-index="0" offset-y>
          <template v-slot:activator="{ on }">
            <v-icon color="white" class="icon" v-on="on">
              mdi-dots-vertical
            </v-icon>
          </template>
          <v-list>
            <v-list-item
              @click="$openModal('linkAgencyModal')"
              v-if="currentTab != 'pending'"
            >
              <v-list-item-title class="px-8">Add Agency</v-list-item-title>
            </v-list-item>
            <v-list-item @click="isSelect = true" v-else>
              <v-list-item-title class="px-8">Select</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
    <div class="mb-2 d-flex">
      <div class="align-self-center">
        ({{ currentTab == 'linkage' ? linkage.length : pending.length }})
      </div>
      <div
        class="ml-auto text-uppercase font-weight-bold lg-font"
        v-if="isSelect && currentTab != 'linkage'"
      >
        <span class="cursor-pointer" @click="approveAgencies">Approve</span>
        <span class="mx-4 font-weight-light">|</span>
        <span class="cursor-pointer" @click="approveAgencies(false)"
          >Reject</span
        >
      </div>
    </div>
    <v-row
      no-gutters
      class="mb-12 pb-12"
      v-if="currentTab == 'linkage' ? linkage.length : pending.length"
    >
      <v-col
        v-for="(item, index) in currentTab == 'linkage' ? linkage : pending"
        :key="index"
        cols="12"
        md="12"
        class="userContainer d-flex"
        :class="index != 0 ? 'mt-5' : ''"
      >
        <div class="d-flex w-100">
          <v-checkbox
            v-if="isSelect && currentTab != 'linkage'"
            on-icon="mdi-check-circle-outline"
            off-icon="mdi-checkbox-blank-circle-outline"
            class="pl-5 align-self-center"
            color="primary"
            :value="item.request_id"
            v-model="requestId"
            number
            dark
          ></v-checkbox>
          <router-link
            class="text-decoration-none d-flex white--text w-100"
            :to="{
              name: routeName.PROFILE,
              query: {
                id: item.agency_id
              }
            }"
          >
            <v-row no-gutters class="my-5 ps-8">
              <v-col cols="12" md="12" class="py-2 align-self-center d-flex">
                <div>
                  <div class="titleFont grey--text">
                    {{ getAgencyCategoryDesc(item.agency_category) }}
                  </div>
                  <div class="nameFont">
                    {{ item.agency }}
                  </div>
                </div>
                <div class="ml-auto me-5 align-self-center">
                  <v-icon class="icon" color="white">
                    mdi-chevron-right
                  </v-icon>
                </div>
              </v-col>
            </v-row>
          </router-link>
        </div>
      </v-col>
    </v-row>
    <v-row class="mb-12 pb-12" no-gutters v-else>
      <v-col cols="12" md="12" class="userContainer d-flex px-5 py-8">
        <div>
          {{
            currentTab == 'linkage' ? 'No linked agency' : 'No pending agency'
          }}
        </div>
      </v-col>
    </v-row>
    <LinkAgencyModal />
  </div>
</template>

<script>
  import {
    PROFILE_INITIAL_GET_LINKED_AGENCY_STATE,
    PROFILE_GET_LINKED_AGENCY,
    PROFILE_INITIAL_APPROVE_AGENCY_LINK_REQUEST_STATE,
    PROFILE_APPROVE_AGENCY_LINK_REQUEST,
    PROFILE_INITIAL_REJECT_AGENCY_LINK_REQUEST_STATE,
    PROFILE_REJECT_AGENCY_LINK_REQUEST,
    PROFILE_GET_AGENCY_PENDING_REQUEST,
    PROFILE_INITIAL_GET_AGENCY_PENDING_REQUEST_STATE
  } from '@/store/profile.module';
  import LinkAgencyModal from '@/components/profile/LinkAgencyModal.vue';
  import { ROUTE_NAME } from '@/constants';

  export default {
    name: 'LinkedAgency',
    components: {
      LinkAgencyModal
    },
    data: () => ({
      linkage: [],
      pending: [],
      routeName: ROUTE_NAME,
      currentTab: 'linkage',
      isSelect: false,
      requestId: [],
      perPage: 10
    }),
    mounted() {
      this.getLinkedAgency();
      this.getAgencyPendingRequest();
      this.$nextTick(() => {
        if (this.$route.params?.tab)
          this.currentTab =
            this.$route.params?.tab == 'pendings' ? 'pending' : 'linkage';
      });
    },
    computed: {
      linkedAgencyComplete() {
        return this.$store.state.profile.linkedAgency.complete;
      },
      getAgencyPendingRequestComplete() {
        return this.$store.state.profile.agencyPendingRequest.complete;
      },
      approveAgencyLinkRequestComplete() {
        return this.$store.state.profile.approveAgencyLinkRequest.complete;
      },
      rejectAgencyLinkRequestComplete() {
        return this.$store.state.profile.rejectAgencyLinkRequest.complete;
      },
      constantsAgencyCategory() {
        return this.$store.getters['constants/agencyCategory'];
      }
    },
    watch: {
      linkedAgencyComplete() {
        let response = this.$store.state.profile.linkedAgency;
        if (response.complete) {
          this.importLinkedAgency(response);
        }
      },
      getAgencyPendingRequestComplete() {
        let response = this.$store.state.profile.agencyPendingRequest;
        if (response.complete) {
          this.importAgencyPendingRequest(response);
        }
      },
      approveAgencyLinkRequestComplete() {
        let response = this.$store.state.profile.approveAgencyLinkRequest;
        if (response.complete) {
          this.respondToLinkRequestCompleteAction(response);
        }
      },
      rejectAgencyLinkRequestComplete() {
        let response = this.$store.state.profile.rejectAgencyLinkRequest;
        if (response.complete) {
          this.respondToLinkRequestCompleteAction(response, false);
        }
      },
      currentTab() {
        this.isSelect = false;
      }
    },
    methods: {
      importLinkedAgency(response) {
        if (response.code == 200) {
          this.linkage = response.data.linkage;
        }
        this.linkedAgencyCompleteAction();
      },

      linkedAgencyCompleteAction() {
        this.initialLinkedAgencyState();
      },
      initialLinkedAgencyState() {
        this.$store.dispatch(PROFILE_INITIAL_GET_LINKED_AGENCY_STATE);
      },
      getLinkedAgency() {
        this.$store.dispatch(PROFILE_GET_LINKED_AGENCY);
      },
      importAgencyPendingRequest(response) {
        if (response.code == 200) {
          this.pending = response.data;
        }
        this.$store.dispatch(PROFILE_INITIAL_GET_AGENCY_PENDING_REQUEST_STATE);
      },
      getAgencyPendingRequest() {
        let query = {
          per_page: this.perPage
        };
        this.$store.dispatch(PROFILE_GET_AGENCY_PENDING_REQUEST, { query });
      },
      respondToLinkRequestCompleteAction(response, isApprove = true) {
        if (response.code != 200) {
          this.$openModal('messageModal', response.message);
        } else {
          this.$openModal(
            'messageModal',
            'You have responded to the request from agency',
            {
              title: 'Done',
              action: () => {
                this.$closeModal();
              }
            }
          );
        }
        this.isSelect = false;
        this.getLinkedAgency();
        this.getAgencyPendingRequest();

        this.$store.dispatch(
          isApprove
            ? PROFILE_INITIAL_APPROVE_AGENCY_LINK_REQUEST_STATE
            : PROFILE_INITIAL_REJECT_AGENCY_LINK_REQUEST_STATE
        );
      },
      approveAgencies(isApprove = true) {
        this.$openModal('messageModal', 'Are you sure to proceed?', {
          title: 'Proceed',
          action: () => {
            let data = this.requestId;
            this.$store.dispatch(
              isApprove
                ? PROFILE_APPROVE_AGENCY_LINK_REQUEST
                : PROFILE_REJECT_AGENCY_LINK_REQUEST,
              { data }
            );
            this.$closeModal();
          }
        });
      },
      getAgencyCategoryDesc(val) {
        return (
          this.constantsAgencyCategory.find((x) => x.value === val)
            ?.description || ''
        );
      }
    }
  };
</script>

<style scoped>
  .userContainer {
    background-color: #242424;
    border-radius: 5px;
  }
  .titleFont {
    font-size: 15px;
  }
  .lg-font {
    font-size: 25px;
  }
  .nameFont {
    font-size: 18px;
  }
</style>
