<template>
  <div>
    <ModalBase id="linkAgencyModal" @closeModal="initialFormValues" persistent>
      <div class="py-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <div v-for="field in fields" :key="field.key" class="my-2">
            <div v-if="field.key == 'agencyId'" class="grey--text mb-3">
              Please select a Company Name or enter Company Unique Code
            </div>
            <AppFormField
              v-if="field.field != 'other'"
              :name="field.key"
              v-model="formValues[field.key]"
              :label="$t(`label.${field.label}`)"
              :rules="field.rules"
              :type="field.formType"
              :items="field.options"
              :hasSearchBar="field.key == 'agencyId'"
              outlined
              clearable
            />
            <AppFormField
              v-if="field.key == 'agencyOther' && isOtherAgency"
              :name="field.key"
              v-model="formValues[field.key]"
              :label="$t(`label.${field.label}`)"
              :rules="field.rules"
              :type="field.formType"
            />
          </div>
          <div class="text-center pt-6 mt-5">
            <v-btn
              class="btn1"
              :disabled="
                !valid || !(formValues.agencyId || formValues.companyCode)
              "
              @click="createLinkRequest"
            >
              Request
            </v-btn>
          </div>
        </v-form>
      </div>
    </ModalBase>
  </div>
</template>

<script>
  import { formValidationHelper } from '@/utils';
  import ModalBase from '@/components/base/ModalBase';
  import { mapGetters } from 'vuex';
  import {
    PROFILE_GET_AGENCY,
    PROFILE_INITIAL_GET_AGENCY_STATE,
    PROFILE_CREATE_AGENCY_LINK_REQUEST,
    PROFILE_INITIAL_CREATE_AGENCY_LINK_REQUEST_STATE
  } from '@/store/profile.module';

  export default {
    name: 'LinkAgencyModal',
    components: {
      ModalBase
    },
    data() {
      return {
        formValues: {
          agencyCategory: null,
          agencyOther: null,
          agencyId: null,
          companyCode: null
        },
        agencies: [],
        valid: false
      };
    },
    computed: {
      ...mapGetters({
        agencyCategory: ['constants/agencyCategory']
      }),
      getAgencyComplete() {
        return this.$store.state.profile.agency.complete;
      },
      createAgencyLinkRequestComplete() {
        return this.$store.state.profile.createAgencyLinkRequest.complete;
      },
      selectedAgencyCategory() {
        return this.formValues.agencyCategory;
      },
      isOtherAgency() {
        return (
          this.formValues.agencyCategory ==
          this.agencyCategory.find((x) => x.key == 'OTHER').value
        );
      },

      fields() {
        return [
          {
            key: 'agencyCategory',
            label: 'agencyCategory',
            formType: 'select',
            options: this.agencyCategory,
            rules: []
          },
          {
            key: 'agencyOther',
            label: 'pleaseSpecify',
            formType: 'text',
            field: 'other',
            rules: []
          },
          {
            key: 'agencyId',
            label: 'companyName',
            formType: 'select',
            options: this.agencies,
            rules: this.formValues.companyCode
              ? []
              : formValidationHelper.requiredRules()
          },
          {
            key: 'companyCode',
            label: 'companyCodeIfAny',
            formType: 'text',
            rules: this.formValues.agencyId
              ? []
              : formValidationHelper.requiredRules()
          }
        ];
      }
    },
    watch: {
      getAgencyComplete() {
        let response = this.$store.state.profile.agency;
        if (response.complete) {
          this.importAgencies(response);
        }
      },
      createAgencyLinkRequestComplete() {
        let response = this.$store.state.profile.createAgencyLinkRequest;
        if (response.complete) {
          this.createAgencyLinkRequestCompleteAction(response);
        }
      },
      selectedAgencyCategory() {
        this.getAgencies();
      }
    },
    methods: {
      importAgencies(response) {
        this.agencies = response.data.map((x) => {
          return {
            ...x,
            text: x.companyName,
            value: x.agencyId
          };
        });
        this.$store.dispatch(PROFILE_INITIAL_GET_AGENCY_STATE);
      },
      getAgencies() {
        const query = {
          agency_category: this.formValues.agencyCategory
        };
        this.$store.dispatch(PROFILE_GET_AGENCY, { query });
      },
      createAgencyLinkRequestCompleteAction(response) {
        this.$closeModal();
        this.$openModal(
          'messageModal',
          response.code == 200
            ? 'Pending approve from agency. Once account linked will be notify in inbox.'
            : response.message
        );
        this.initialFormValues();
        this.$store.dispatch(PROFILE_INITIAL_CREATE_AGENCY_LINK_REQUEST_STATE);
      },
      createLinkRequest() {
        if (!this.$refs.form.validate()) return;
        const data = {
          companyCode:
            this.formValues.companyCode ||
            this.agencies.find((x) => x.agencyId == this.formValues.agencyId)
              .companyCode
        };
        this.$store.dispatch(PROFILE_CREATE_AGENCY_LINK_REQUEST, { data });
      },
      initialFormValues() {
        this.formValues = {
          agencyCategory: null,
          agencyOther: null,
          agencyId: null,
          companyCode: null
        };
        this.getAgencies();
      }
    },
    mounted() {
      this.getAgencies();
    }
  };
</script>

<style></style>
